//@import './assets/stylings/myeg-fonts';
@import "../src/assets/stylings/myeg-fonts.scss";

$Yellow: #ffbf50;
.form-control {
height: 45px;
}

html body {
  font-size: 19px;
  height: 100%;
}

html, body {
  overflow-x: hidden;
}

html:has(app-ai-chatbot-chatbox) {
  @media (width < 500px) {
    overflow: hidden;
  }
}

body {
  font-family: "bariolregular", Helvetica, Arial, sans-serif;
  color: #7f7f7f !important;
  line-height: 1.2;
  overflow-x: hidden;
}
#body_wraper {
  padding-top: 50px;
  min-height: calc(100vh - 40px);
  &:has(.status-page) {
    @media (width < 600px) {
      min-height: unset;
    }
  }
}
.help-block {
  color: #a94442;
}
option {
  font:normal normal 500 14px/22px Open Sans;
  color: #7f7f7f;
 }
.container {
  font: normal normal 500 16px/20px Raleway;
  width: 89%;
}

//texts
.t-primary {
  color: #e53e46 !important;
}

.t-primary-black {
  color: #5F5F5F !important;
}

.t-primary-italic {
  color: #e53e46 !important;
  font-style: italic;
  font-weight: 400;
}

.t-normal {
  color: black !important;
  font-weight: 500;
}

.t-light-normal {
  color: #999999 !important;
  font-weight: 400;
}

//margins {
.mt-22 {
  margin-top: 22px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-48 {
  margin-bottom: 48px;
}

//paddings
.pl-24 {
  padding-left: 24px !important;
}

.pl-17 {
  padding-left: 17px !important;
}

.pt-18 {
  padding-top: 18px !important;
}

//buttons

.btn-eg {
  color: #e53e46 !important;
  border-radius: 8px;
  opacity: 1;
  font: normal normal 600 14px/19px Open Sans, semi bold;
  letter-spacing: 0px;
  padding: 13px 14px !important;
}

.btn-eg:hover {
  color: rgb(237, 221, 221) !important;
  background: #a73439 !important;
  cursor: pointer;
}

.button-big {
  font: normal normal 700 18px Raleway;
  height: 46px;
  padding: 14px;
  color: white !important;
  border: 0;
  border-radius: 8px;
  background: #e53e46;
  box-shadow: 0px 4px 0px 0px #c52328;
  width: 328px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    text-decoration: none;
  }

  &:disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }

  @media (width < 600px) {
    font-size: 16px;
  }
}

//bg
.bg-eg {
  background-color: #e53e46 !important;
  color: #ffffff !important;
}

.mt-12 {
  margin-top: 12px;
}
.rounded-24 {
  border-radius: 24px;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-bold {
  font-weight: bold;
}
.fs-12 {
  font-size: 12px;
}

.fs-16 {
  font-size: 16px;
}

.fs-20 {
  font-size: 20px;
}

.check-container {
  display: block;
  position: relative;
  // padding-left: 35px;
  padding-left: 10px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~ {
        .checkmark {
          background-color: #e53e46;
          border-radius: 3px;
          &:after {
            display: block;
          }
        }
      }
    }
  }
  // &:hover {
  // 	input {
  // 		~ {
  // 			.checkmark {
  // 				background-color: #ccc;
  // 			}
  // 		}
  // 	}
  // }
  .checkmark {
    &:after {
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}

.m-0-imp {
  margin: 0 !important;
}

:focus-visible {
  outline: #bcbcbc auto 1px;
}

.input-error {
  outline: #e53e46 auto 1px;
}

.modal-content {
  font: normal normal 600 16px/22px Open Sans;
}

.modal-size-750 {
  width: 750px;
}
@media (min-width: 992px){
  .modal-angkasa {
    width: 750px;
  }
}
.addon_customization{
  font: normal normal 600 16px/24px Open Sans;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
}

.my-custom-class .tooltip-inner {
  background-color: darkgreen;
  font-size: 125%;
}
.my-custom-class.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: darkgreen;
}
.my-custom-class.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: darkgreen;
}
.my-custom-class.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: darkgreen;
}
.my-custom-class.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: darkgreen;
}

.input-group-addon {
  background-color: #C52328;
  color: white;
}

.datepicker table tr td.active.highlighted {
  color: #fff;
  background-color: $Yellow;
  border-color: $Yellow;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  color: #111;
  background-color: $Yellow;
  border-color: $Yellow;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.addon_width {
  width: 100% !important;
}
input[type="text"],
input[type="email"],
input[type="date"],
input[type="number"],
input[type="search"] {
  background: #fff;
  //box-shadow: 0px 2px 4px #00000033;
  border: 1px solid #bcbcbc;
  border-radius: 4px;
  // padding: 12px;
  padding: 9px;
  width: 100%;
  color: #000000;
  font-weight: 500;
  // margin-top: 5px;
  &:disabled {
    border-color: #ddd;
  }
}
input[type="date"] {
  padding: 9px 12px;
}
input[type="search"] {
  font-family: "bariolregular", Helvetica, Arial, sans-serif;
  color: #7f7f7f !important;
  line-height: 1.2;
  font-size: 18px;
}

input[type="text"],
input[type="email"],
select {
    font-family: Open Sans;
    //margin: 0px 0px 10px 0px;
    display: inline-block;
    border-radius: 4px;
    box-sizing: border-box;
    height: 45px;
    width: 100%;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #bcbcbc;
    background: #fff;
    padding: 9px;
    color: #000000;
    &:disabled {
      cursor: not-allowed;
      opacity: 0.2;
    }
  }

::-webkit-input-placeholder {
  /* Chrome */
  color: #D1D1D1;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #D1D1D1;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #D1D1D1;
  opacity: 1;
}
:-moz-placeholder {
  /* Firefox 4 - 18 */
  color: #D1D1D1;
  opacity: 1;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  @extend input, [type="text"];
  padding: 9px;
  font-weight: 400;
}
.select2-selection__rendered {
  @extend input, [type="text"];
  padding: 10px 12px;
}
.select2-selection {
  box-shadow: none;
  border: none;
}
label {
  //   text-transform: capitalize;
  font-weight: 400;
  color: #000;
}

.detailed-wrap {
  padding-top: 25px;
  @media (max-width: 1200px) {
    padding-top: 0;
  }
}

/** Footnotes */
*:has(> .footnote-mark) {
  counter-reset: footnote-mark;
}

*:has(> .footnote-list) {
  counter-reset: footnote-list;
}

.footnote {
  font-size: 12px;
  color: #434343;
  font-weight: 600;
  top: -1em;
  position: relative;
  display: inline-block;
}

.footnote-mark::after {
  @extend .footnote;
  counter-increment: footnote-mark;
  margin-left: 2px;
  content: "[" counter(footnote-mark)"]";
}

.footnote-list::before {
  @extend .footnote;
  counter-increment: footnote-list;
  margin-right: 2px;
  content: "[" counter(footnote-list)"]";
}
.hidden {
  display: none !important;
}

.block {
  display: block !important;
}

.sm\:block {
  @media (width >= 600px) {
    display: block !important;
  }
}

.sm\:hidden {
  @media (width >= 600px) {
    display: none !important;
  }
}


/** Tabs */
.tabs {
  container-type: inline-size;

  .mat-tab-header {
    border-bottom: 1px solid #DCDCDC;
  }

  .mat-tab-header-pagination {
    display: none !important;
  }

  .mat-tab-label {
    border-radius: 20px 20px 0 0;
    opacity: 1;
    color: #373737;
    font-family: 'Raleway';
    font-size: 15px;
    font-weight: 600;
    min-width: 0;

    @container (width < 600px) {
      height: auto;
      padding: 10px 15px;
      min-width: 0;
      max-width: 33%;
    }
  }

  .mat-tab-label-active {
    background-color: #FFEEE9;
    color: #E53E46;
    font-weight: 700;
  }

  .mat-ink-bar {
    height: 5px;
    background-color: #C52328;
    transition: none;
    min-width: 0;
    max-width: 33%;
  }

  .mat-tab-body-wrapper {
    margin-top: 25px;
  }
}

.tab-button {
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;

  @container (width < 600px) {
    flex-direction: column;
  }
}

/** Dialog */
dialog {
  width: 100%;
  max-height: 100dvh;
  padding: 24px;
  border: none;
  background-color: transparent;

  &::backdrop {
    background: #00000080;
  }

  body:has(&[open]) {
    overflow: hidden;
    height: 100dvh;
  }
}

.dialog-content {
  width: 100%;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 12px 27px 0px #8080801A;
  overflow: hidden;
  position: relative;
}

.dialog-close {
  --size: 36px;
  width: var(--size);
  height: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  padding: 0;
  border: 0;
  position: absolute;
  right: 10px;
  top: 10px;

  &::before {
    content: url('data:image/svg+xml,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.8516 8.59375L16.7378 3.70752C17.3374 3.10791 17.3374 2.13574 16.7378 1.53564L15.6519 0.449707C15.0522 -0.149902 14.0801 -0.149902 13.48 0.449707L8.59375 5.33594L3.70752 0.449707C3.10791 -0.149902 2.13574 -0.149902 1.53564 0.449707L0.449707 1.53564C-0.149902 2.13525 -0.149902 3.10742 0.449707 3.70752L5.33594 8.59375L0.449707 13.48C-0.149902 14.0796 -0.149902 15.0518 0.449707 15.6519L1.53564 16.7378C2.13525 17.3374 3.10791 17.3374 3.70752 16.7378L8.59375 11.8516L13.48 16.7378C14.0796 17.3374 15.0522 17.3374 15.6519 16.7378L16.7378 15.6519C17.3374 15.0522 17.3374 14.0801 16.7378 13.48L11.8516 8.59375Z" fill="black"/></svg>');
    width: 50%;
    height: 50%;
  }
}

.vertical-side-group {
  position: fixed;
  top: 10vh;
  right: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 12px;

  button {
    padding: 24px 11px;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    text-align: center;
    color: white;
    background-color: #C52328;
    border-radius: 15px 0 0 15px;
    border: 0;
    writing-mode: vertical-lr;

    html:not([lang="cn"]) & span {
      display: block;
      rotate: 180deg;
    }

    @media (width < 600px) {
      padding: 20px 7px;
      font-size: 15px;
    }
  }
}

/** Language */
[class*="lang-"] {
  display: none;
}

:is(:lang(en), [lang="en"]) {
  .lang-en {
    display: block;
    &.inline {
      display: inline;
    }
  }
}

:is(:lang(ms), [lang="ms"]) {
  .lang-my {
    display: block;
    &.inline {
      display: inline;
    }
  }
}

:is(:lang(zh), [lang="zh"]) {
  .lang-cn {
    display: block;
    &.inline {
      display: inline;
    }
  }
}

.text-black {
  color: #000
}

@for $i from 1 through 12 {
  .col-span-#{$i} {
    grid-column: span $i / span $i;
  }

  @media (width > 600px) {
    .sm\:col-span-#{$i} {
      grid-column: span $i / span $i !important;
    }
  }
}

.grecaptcha-badge {
  opacity: 0;
  pointer-events: none;
}