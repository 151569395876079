/* Generated by Font Squirrel (https://www.fontsquirrel.com) on November 16, 2016 */

@font-face {
  font-family: 'bariolbold';
  src:  url('/assets/fonts/bariol/bariol_bold-webfont.woff2') format('woff2'),
        url('/assets/fonts/bariol/bariol_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
  
@font-face {
  font-family: 'bariollight';
  src:  url('/assets/fonts/bariol/bariol_light-webfont.woff2') format('woff2'),
        url('/assets/fonts/bariol/bariol_light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
  
@font-face {
  font-family: 'bariolregular';
  src:  url('/assets/fonts/bariol/bariol_regular-webfont.woff2') format('woff2'),
        url('/assets/fonts/bariol/bariol_regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
  
@font-face {
  font-family: 'bariolthin';
  src:  url('/assets/fonts/bariol/bariol_thin-webfont.woff2') format('woff2'),
        url('/assets/fonts/bariol/bariol_thin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
  
@font-face {
  font-family: 'alte_haas_groteskbold';
  src:  url('/assets/fonts/altehaasgrotesk/altehaasgroteskbold-webfont.woff2') format('woff2'),
        url('/assets/fonts/altehaasgrotesk/altehaasgroteskbold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
  
@font-face {
  font-family: 'alte_haas_groteskregular';
  src:  url('/assets/fonts/altehaasgrotesk/altehaasgroteskregular-webfont.woff2') format('woff2'),
        url('/assets/fonts/altehaasgrotesk/altehaasgroteskregular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
  
@font-face {
  font-family: 'Open Sans';
  src: url('~assets/fonts/Open_Sans/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans, semi bold';
  src: url('~assets/fonts/Open_Sans/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('~assets/fonts/Raleway/static/Raleway-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway, semi bold';
  src: url('~assets/fonts/Raleway/static/Raleway-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
  